.dropdown-menu {
   display: block;
    background: #610B0B;
    width: 120px;
    position: absolute;
    top: 80px;  
    left:750px;
    list-style: none;
    text-align: start;
  }
  
  .dropdown-menu li {
    background: #F3F781;
    cursor: pointer;
  }
  
  .dropdown-menu li:hover {
    background: #610B0B;
  }
  
  .dropdown-menu.clicked {
    display: none;
  }
  
  .dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: darkgreen;
    padding: 16px;
  }
  
  @media screen and (max-width: 960px) {
    .dropdown-menu {
       display: none; 
       background: darkgreen;
       width: 100px;
       position: absolute;
       top: 140px;
       left:400px;
       list-style: none;
       text-align: start;
     }
    .fa-caret-down {
      display: none;
    }
  }
  
  @media screen and (min-width: 1400px) {
    .dropdown-menu {
      display: block; 
      background: darkgreen;
      width: 100px;
      position: absolute;
      top: 80px;
      left:1300px;
      list-style: none;
      text-align: start;
    }
   .fa-caret-down {
     display: none;   
   }
  }