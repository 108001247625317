
.prodcard{
    width: 20rem;
    /* 100%; */
    /* 14rem;  */
    height:auto;
    border:none;
    align-content: center;  
    background-color: transparent;
    padding-bottom: 20px;

  
    /* padding:2px;  */
}

.fadeprod{
    margin-left: auto;
    margin-right: auto;
}
.card a{
    color:#006536;
    font-weight: bold;
}

/* .prodcard:hover{
    box-shadow: 1px rgba(0,0,0,153)!important;
    /* box-shadow: 5px 10px 20px 1px rgba(0,0,0,253)!important; 
} */

.prodcard-body{
padding:0.5rem 0!important;
}

.text-secondary
{
    color:black !important;
    text-align: left !important;
}
.card-text
{
    font-size: 20px;
     /* 0.9rem; */
    /* padding: 0.4rem 1.9rem; */
    color:black;
    text-align: left !important;
    /* font-family: Arial, Helvetica, sans-serif; */
}

/* .container-fluid-row{
    padding-top: 6rem;
} */

.cta{
    padding-top: 5px;
    padding-bottom: 5px;
    height:40px;
    width: 100%;
    /* min-width: 100vw; */
    background-color: #006536;
    color: white;
}

@media screen and (min-width: 340px) and (max-width: 960px) {
    .prodcard{
        width:20rem;
        height:fit-content;
        border-style: none;
        border-width: 1px;
        border-color: #89191c;
        padding-left: 3px;
    }
    .img
    {
        width:300px;
        height: auto;
    }
   }

   @media screen and (min-width: 1400px) {
    .prodcard{
        width:20rem;
        height:fit-content;
        border-style: none;
        border-width: 1px;
        border-color: #89191c;
        padding-left: 3px;
    }  
   }

   @media screen and (min-width: 320px) and (max-width: 340px) {
    .prodcard{
        width:40rem;
        height:fit-content;
        border-style: none;
        border-width: 1px;
        border-color: #89191c;
        padding-left: 3px;
    }
    .img
    {
        width:280px;
        height: auto;
    }
   }

   @media screen and (min-width: 768px){
        .img
        {
            width:700px;
            height: auto;
        }
   }