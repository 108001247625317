.privacy-h2{
    /* position: fixed; */
    padding-left: 600px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    font-size: xx-large;
    font-weight: bolder;
    text-decoration: underline;
    color: darkblue;
    margin-bottom: 30px;
  }

  .policy{
    margin-top: 10px;
  }

  .whyus{
    border-radius: 1px;
    border-color: #e1653c;
    margin-left: auto;
    margin-right: auto;
  }

  .banner{
    width: 100%;
    height: 50%;
    padding-top: 0px;  
   
}

.bannerimg{
    
   width: auto;
    height:56vw;
    
}

.imgclosure
{
    width: 100%;
    height: 100vh;
}
.rcs_sliderSet {
	height: 370px;
    
}

.rcs_sliderBox{
    height: 370px; 
}

.readmoreLink{
  background-color: #262647;
  color: white;
  border: black;
  width: 200px;
  height: 40px;
  border-radius: 0.5rem;
  margin-left:600px;
  margin-right:auto;
  font-style: italic;
  align-content: center;
  text-align: center;
  font-size: 20px;
  font-family: 'Fraunces';
  /* display: flex; */
}

.readmoreLink:hover {
  color:#ef4623;
}

.gallery{
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.rcst_left {
  top:70%;
}
.rcst_right {
  top:70%;
  /* margin-right: 80px; */
}

@media screen and (min-width:375px) and (max-width: 680px){
  .whyus{
    border-radius: 1px;
    border-color: #e1653c;
    /* margin-left: auto;
    margin-right: auto; */
  }
  .readmoreLink{
    margin-left: 100px;
    display: block;
    margin-right: 0px;
    /* width: 100px; */
    /* font-size: 10px; */
  }
}


@media screen and (min-width:800px) and (max-width: 1180px) {
  .readmoreLink{
    margin-left: auto;
    margin-right: auto;
  }
  }

  @media screen and (min-width:250px) and (max-width: 700px) {
    .readmoreLink{
      margin-left: auto;
      margin-right: auto;
    }  
  }