body {
  margin: 0;
  font-family: 'Assistant', sans-serif;
/* font-family: 'Roboto', sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  /* Font-family: 'Baskerville', 'Baskerville Old Face', 'Garamond', 'Times New Roman', 'serif'; */
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: "url('https://mathsutra.s3.ap-south-1.amazonaws.com/ms-bg-image-1.png')";
  background-repeat: no-repeat;
  background-size: 'cover';
  height: 100%;
  /* background-color: white; */
  
}

html, body{
  height: 100%;
  width: 100%;
  background-image: "url('https://mathsutra.s3.ap-south-1.amazonaws.com/ms-bg-image-1.png')";
  background-repeat: no-repeat;
  background-size: 'cover';
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
