.footer {
  display: flex;
  /* flex-direction: column; */
  
  color: black;  
  margin-right: 0px;
  margin-left: 0px;
  position: relative;
  bottom: 0px;
  right:0px;
  left:0px; 
  height: 30px;
  width:auto; 
  background-color: #262647;
  font-size: 1rem;
}

.copyright{
  color: #e1653c;
  font-weight: bolder;
  font-size: 1rem;      
  background-color: #262647;
  /* #e1653c; */
 
  text-align: left !important;
 
}

.powered{
  background-color: #262647;
  text-align: right !important;
  /* color: white;  */
  /* #262647; */
  font-size: 1rem;
  font-weight: bolder;
}



@media screen and (min-width: 200px) and (max-width: 340px) {
  
}

@media screen and (min-width: 340px) and (max-width: 960px) {
  .footer {
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
    padding-bottom: 0px;
    position: relative;
    bottom: 0px;
    height: 25px;
    width:100%;
    clear: both;
  }
  .copyright{
    text-align: center !important;
  }

  .powered{
    text-align: center !important;
  }


  
  
}

@media screen and (min-width: 770px) and (max-width: 1125px) 
{

  
}

@media screen and (min-width: 1400px)  
{
  .footer {
    display: flex;
    flex-direction: column;    
    align-content: center;
    justify-content: center;
    color: black;  
    margin-right: 0px;
    margin-left: 0px;
    position: relative;
    bottom: 0px;
    right:0px;
    left:0px; 
    height: 100%;
    width:100%;  
    background-color: #262647;
    font-size: 1rem;
    clear: both;
  }

  .copyright{
    text-align: center !important;
  }

  .powered{
    text-align: center !important;
  }
}
