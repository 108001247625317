.go-top {
    position: fixed;
    cursor: pointer;
    bottom: 45px;
    /* 10px; */
    right: 0;
    color: black;
    background-color: #ef4623;
    z-index: 1;
    width: 40px;
    /* 55px; */
    text-align: center;
    height: 40px; 
    /* 45px; */
    border-radius:  10px 0 0 10px;
    /* 10px 0 0 10px; */
    line-height: 40px; 
    /* 46px; */
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }