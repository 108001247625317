.App {
  text-align: center;
  height:100%;
 
  /* background-color: #BFBFBE; */
  /* background-color: white; */
  background-image: "url('https://mathsutra.s3.ap-south-1.amazonaws.com/ms-bg-image-1.png')";
  background-repeat: no-repeat;
  background-size: 'cover';
}

.why-h2{
  font-family: 'Fraunces';
  font-size: 60px;
  font-weight: bold;
  font-style: italic;
  color:#e1653c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
 
}

.banner-top
{
  padding-top:55px;
}
.team{
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.aboutus-p
{
  text-align: justify;
  font-weight: lighter;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  font-family: "znikoslsvginot-font-f55359";
  font-size: larger;
  /* font-stretch: semi-expanded; */
}

.aboutus-p-bold
{
  text-align: justify;
  font-weight: bolder;
  /* font-family: Verdana, Geneva, Tahoma, sans-serif; */
  font-family: "znikoslsvginot-font-f55359";
  font-size: larger;
  /* font-stretch: semi-expanded; */
}

.img{
  width: 100% !important;
}

.reservessection
{
  padding-top:20px;
  padding-bottom: 300px;
}

.aboutus-div{
  /* border-radius: 1px; */
  /* border-color: brown; */
  /* border-style: solid; */
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
 
}

.aboutus{
  /* padding-top: 300px; */
  margin-left: 10px;
  text-align: left;
  font-weight: bold;
  /* color: #610B0B; */
  color: black;
}

.aboutus-h1
{
  text-align: left;
  margin-left: 14px;
  font-size: 22px;
  color: black;
  font-weight: bold;
  /* text-transform: uppercase; */
   /* text-decoration: underline; */
 /* font-weight: 400; */
  /* color: #610B0B; */
}

.bright{
  color: #89191c;
  font-weight: bolder;
}

.aca{
  color: #006536;
  font-weight: bolder;
}
.aboutus-h3
{
  text-align: left;
  margin-left: 14px;
  font-size: 20px;
  color: black;
  font-weight: bold;
  font-style: italic;
 }



.aboutus-p-reservation
{
  text-align: left;
  margin-left: 14px;
  font-size: 28px;
  font-weight:bold;
  color: black;
  font-family: 'Open Sans', sans-serif !important;
  
}

.aboutus-p-header{
  text-align: left;
  margin-left: 14px;
  font-size: 40px;
 
  font-weight:bolder;
  color: #3e6916;
  font-family: 'Open Sans', sans-serif !important;  
}

.namestyle{
  color: #3e6916;
  font-family: 'Satisfy', cursive !important;
  font-weight: bolder;
  font-size: 20px;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.emptyDivclass{
  padding-top: 20px;
  padding-bottom: 40px;
  height: 100vh;
 /* min-height: max-content; */
}

.App-link {
  color:black;
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

.video{
  width : 600px;
  height: 300px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}
/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
      margin-top: 10px;
  }

  .whatsapp_float {
      width: 40px;
      height: 40px;
      bottom: 20px;
      right: 10px;
      font-size: 22px;
  }
  .row{
    display: block !important;
  }
}

@media (min-width:320px) and (max-width: 700px){
  .img{
    width: 400px !important;
    height: 400px!important;
  }
  .row{
    display: block !important;
  }
 
  .team{
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width:400px;
    height: 300px;
  }
}

@media screen and (min-width:800px) and (max-width: 1180px) {
.why-h2{
    font-size: 40px; 
   
}

}


@media screen and (min-width:250px) and (max-width: 700px) {
.aboutimage
{
  width:100vw;
}
.team{
  margin-left: 15px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
  width:100vw;
  height: 300px;
}

.video{
  width : 100vw;
  height: 300px;
  /* margin-left: 10px;
  margin-right: 10px; */
  margin-bottom: 20px;
}
.iframe[Attributes][Style]
{
  width : 80vw !important;
}
}