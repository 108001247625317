.form {
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
    width: 50%;   
   
    padding:20px;
    /* border: #e1653c;   
    border-radius: 0.1rem;
    border-style: groove; */
    margin-left: auto;
    margin-right: auto;
   
  }
  
  .form-h2 {
    margin: 0;
    font-weight: 900;
    font-size: 20px;
   padding: 10px;
   margin: 10px;
   color: black;
   font-family: 'Calistoga'; 
  }
  
  .form-label {
    margin: 10px 10px;
    text-align: left; 
    
    width: 50%;
    /* padding:10px; */
    color: #e1653c;
    /* font-weight: ; */
    font-family: 'Calistoga';

    
  }
  
  .form-input, .form-textarea
  {
    /* display: flex; */
    padding-right: 10px;
    border: thin;
    border-style: solid;
    margin-top: 5px;
    border-radius: 2px;
    padding: 10px;
    width: 250px;
    font-size: 1rem; 
    align-content: center; 
    justify-items: center;
  }
  
  .form-textarea{

    padding-bottom: 10px;
    
  }

  .countrydropdown{
    margin-top: 10px;
    margin-bottom: 15px;
    width: 250px;
    color: black;
  }
   
  .form-submit {
    align-content: center; 
    font-weight: 900;
    font-family: 'Calistoga';
    background-color: #262647;   
    /* background-color: white; */
    color: white;
    padding: 6px 18px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;   
    cursor: pointer;
    border:black;
    border-style: solid;
    border-width: thin;
  }

  .label-submit {
    align-content: center;
    font-weight: 900;
    font-family: 'Calistoga';
    /* background-color: #BFBFBE;    */
    /* background-color: white; */
    color: black;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 18px;  
    border:none;
  }
  
 
  
  @media screen and (min-width: 300px) and (max-width: 960px) {
    .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw; 
   margin: 0px;
   padding: 0px;
    
    }
      
    .form-h2 {
      /* grid-area: header;
      text-align: left; */
      margin: 0;
      font-weight: 900;
      font-size: 28px;
      padding: 10px;
      margin: 10px;
      color: #ef4623;
      /* text-decoration: underline; */
    }
  
    .form-label {
      margin: 0;
    }
  
    #name {
      grid-area: name;
    }
    #email {
      grid-area: email;
    }
     
    .form-submit {
      grid-area: submit;
    }
  }
  
  @media screen and (min-width:250px) and (max-width: 400px) {
    .form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      
      align-items: flex-start;
      width: 100vw !important; 
      margin: 0px;  
      padding: 0px;
      /* margin-left: auto;
      margin-right: auto; */
      
      }
  }

  @media screen and (min-width:360px) and (max-width: 780px) {
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      align-items: center;
      width: 100vw !important; 
      margin: 0px;  
      padding: 0px;
      /* margin-left: auto;
      margin-right: auto; */
      
      }
  }

  /* @media screen and (min-width:1024px) and (max-width: 600px) {
    .form {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      
      align-items: flex-start;
      width: 100vw !important; 
      margin: 0px;  
      padding: 0px;
      
      
      }
  } */