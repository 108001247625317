.info {
  text-align: center;
  padding: 10px;
  /* background-color: #74d680; */
   /* #8DC26F; */
 background-color:#e1653c ;
  color: white;
}

.info-h2 {
  font-family: 'Fraunces';
  font-size: 60px;
  font-weight: bold;
  font-style: italic;
  color:#262647;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

.info-details-container {
  margin: 30px 0;
  font-weight: bold;
}

.info-detail {
  /* font-weight: 600; */
  font-family: 'Fraunces';
  font-size: 20px;
  margin-left: auto;
  margin-right: auto;
}

.info-icons-container {
  display: flex; 
  width: 100%;
  color: white;
  padding-left: 550px;
  /* margin-left: auto;
  margin-right: auto; */
}

.info-icon {
  font-size: 3rem;
  margin-right: 20px;
  cursor: pointer;
  color: white;
 align-self: center;
}

@media screen and (min-width: 699px) {
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 40px;
  }

  .info-detail {
    line-height: 2;
  }
  .info-details-container {
    margin: 10px 0;
  }
  

}

@media screen and (min-width:253px) and (max-width: 653px) {
  .info-icons-container {
    /* margin: 10px 0; */
    padding-left: 50px !important;
    
  }
}

@media screen and (min-width:400px) and (max-width: 700px) {
  .info-icons-container {
   
    padding-left: 100px !important;
    
  }
}

@media screen and (min-width:600px) and (max-width: 1024px) {
  .info-icons-container {
   
    padding-left: 250px !important;
       
  }
}

@media screen and (min-width:540px) and (max-width: 720px) {
  .info-icons-container {
   
    padding-left: 200px !important;
    
  }
}


@media screen and (min-width:600px) and (max-width: 1280px) {
  .info-icons-container {
   
    padding-left: 400px;
    
  }
}