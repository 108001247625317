
p{
    font-family:"Fjalla One", sans-serif !important;
    font-size: 20px;
}
.aboutusmain-h1{
    font-family: 'Calistoga';
  font-size:50px;
  font-weight: bolder;
  width: 100%;
  color:#e1653c ;
  margin-bottom: 20px;
  }

  .img{
    width:600px !important;
    height: 600px !important;

  }
.aboutus-h2{
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: 'Calistoga';
    font-size: 28px;
    font-weight: bolder;
    color:#e1653c ;
    /* padding-left: 600px; */
    text-align: center;
    width:100%;
    margin-bottom: 10px;
}


.aboutusmain-p
{
  text-align: justify;
  font-weight: lighter;
  font-family: 'Calistoga';
  font-size: 22px;
  }

.aboutusbannerimg{
    height:500px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;       
 }

.space{
    margin-top: 10px;
  }

.card{
    width: 100%;
    /* 14rem;  */
    height:auto;
    border:none;
    align-content: center;  
    background-color: transparent;
    padding:2px; 
}

.cardposition{
    margin-left: auto;
    margin-right: auto;
}

.card a{
    color:#006536;
    font-weight: bold;
}

.card:hover{
   
    box-shadow: 5px 10px 20px 1px rgba(0,0,0,253)!important;
}

.card-body{
    padding:0.5rem 0!important;
}

.text-secondary
{
    color:black !important;
}
.card-text
{
    font-size: 20px;
     /* 0.9rem; */
    /* padding: 0.4rem 1.9rem; */
    color:black;
    text-align: justify;
    font-family: 'Calistoga';
}

.card-title{
    font-size: 24px;        
    display: contents;
    text-align: center;
    align-items: center;
    font-family: 'Calistoga';
    color: #e1653c;
    font-weight: bolder;
    padding: 20px;
}

/* .container-fluid-row{
    padding-top: 6rem;
} */

.button{
    padding-top: 5px;
    padding-bottom: 5px;
    /* flex-direction: column; */
    height:40px;
    width: 200px;
    text-align: center;
    font-size: 18px;
    background-color: #262647;
    color: white;
    margin-left: auto;
    margin-right: auto;
    border: 0px;
}

@media screen and (min-width: 340px) and (max-width: 960px) {
    .card{
        width:20rem;
        height:fit-content;
        border-style: none;
        border-width: 1px;
        border-color: #89191c;
        padding-left: 3px;
    }
    .img
    {
        width:300px;
        height: auto;
    }
   }

   @media screen and (min-width: 1400px) {
    .card{
        width:20rem;
        height:fit-content;
        border-style: none;
        border-width: 1px;
        border-color: #89191c;
        padding-left: 3px;
    }  
   }

   @media screen and (min-width: 320px) and (max-width: 340px) {
    .card{
        width:40rem;
        height:fit-content;
        border-style: none;
        border-width: 1px;
        border-color: #89191c;
        padding-left: 3px;
    }
    .img
    {
        width:280px;
        height: auto;
    }
   }

   @media screen and (min-width: 768px){
        .img
        {
            width:700px;
            height: auto;
        }
   }

   @media screen and (min-width:800px) and (max-width: 1180px) {

   .aboutusbannerimg{
    width:100vw;
   }
   }

   @media screen and (min-width:250px) and (max-width: 700px) {

    .aboutusbannerimg{
     width:100vw;
     height:auto;
    }
    
    }