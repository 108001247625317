.mapinfo {
  display: flex;
    padding-top: 20px;
    padding-bottom: 40px;
    margin-bottom: var(--offset-height);
    margin-left: 130px;
    width: 81%;
    height: 450px;
    border-color: #e1653c;
    overflow: hidden;
    position: relative;    
  }

 .mapinfo iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    bottom:0px;
    
 }

 .mapbg
 {
  background-color: #e1653c;
 }
  @media screen and (max-width: 960px) {
    .mapinfo {     
      margin-left: auto;
      margin-right: auto;
    
      width: auto;
      height: 500px;
      border-color: khaki;
      overflow: hidden;
      position:relative;
    }

    .mapinfo iframe {
      border: 0;
      height: 100%;
      float: none;
      left: 0;
      position: relative;
      top: 0;
      width: 100%;
      bottom:40px;
   }
  }

  @media screen and (min-width: 1400px)  
{
  .mapinfo {
    display: flex;
      padding-top: 20px;
      padding-bottom: 40px;
      margin-bottom: var(--offset-height);
      margin-left: 180px;
      width: 81%;
      height: 450px;
      border-color: khaki;
      overflow: hidden;
      position: relative;
    }
  }