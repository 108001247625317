.navbar {  
    /* height: 145px; */
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    background-color: white; 
    margin-bottom: 30px;
     
  }
  
  .navbar-logo {
      padding-top: 20px;
    padding-bottom: 60px;
     margin-left: 0px; 
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    /* float:inherit; */
  
  }

  .navbar-img{
    width:300px;
    height:70px;
    padding:0px;
    margin-top: 0px;
    margin-bottom: 15px;
    margin-left: 0px;
    float: left;
    
  }
  
  .fa-firstdraft {
    padding-top: 0px;
    /* margin-left: 0.5rem; */
    font-size: 1.6rem;
    
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 8px;
    list-style: none;
    text-align: center;
    width: 60vw;
    padding-top: 0px;
    padding-bottom: 30px;
  
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    display: flex;
    align-items: right;
    height: 20px;
  }
  
  .nav-links {
    color:  #262647; 
   font-size: large;
   font-weight: bolder;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-family: 'Fraunces';
    font-style: italic;    
  }
  
  .nav-links:hover {
       color:#ef4623;
    text-decoration: none;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #ef4623;
   }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
 
  @media screen and (max-width: 960px) {

    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      float:right;
      flex-direction: column;
      width: 100%;
      height: 20vh;
      position: absolute;
      top: 85px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }

    .nav-links-mobile {
      display: block;
    }
  
    .nav-menu.active {
      /* background: #ef3214; */
      background:white ;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      /* padding: 2rem; */
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      /* background-color:#262647; */
      border-radius: 0px;
    }
  
    .navbar-logo {
    
      position: absolute;
      padding-left: 0px;
      top: 0;
      left: 0;
      /* transform: translate(25%, 50%); */
    }

    .navbar-img{
      width:200px;
      height:60px;
      padding:0px;
      margin-top: 0px;
      margin-bottom: 5px;

      float: left;
      align-items: flex-start;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #ef4623;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 40%;
      /* background: #ef3214; */
      background-color: white;
      text-decoration: none;
      color: black;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: royalblue;
      transition: 250ms;
    }
  
    button {
      display: none;
    }
  }

  @media screen and (min-width:768px) and (max-width: 960px) {
  

  .navbar-logo {
      position: absolute;
      padding-left: 0px;
     bottom: 0px;
     
      top:0px;
      left: 0;
      /* transform: translate(25%, 50%); */
    }

    .nav-menu {
      height:20vh;
    }

  }

  @media screen and (min-width:400px) and (max-width: 736px) {
    .navbar-logo {
      position: absolute;
      padding-left: 0px;
     bottom: 0px;     
      top:0px;
      left: 0;
      /* transform: translate(25%, 50%); */
    }

    .nav-menu {
      height:20vh;
    }
  }

  @media screen and (min-width:320px) and (max-width: 400px) {
    .navbar-logo {
      position: absolute;
      padding-left: 0px;
     bottom: 0px;     
      top:0px;
      left: 0;
      /* transform: translate(25%, 50%); */
    }

    .nav-menu {
      height:20vh;
    }
  }

  @media screen and (min-width:375px) and (max-width:375px){
    .navbar-logo {
      position: absolute;
      padding-left: 0px;
      padding-bottom: 70px;
     bottom: 0px;     
      top:0px;
      left: 0;
      /* transform: translate(25%, 50%); */
    }

    .nav-menu {
      display: flex;
      float:right;
      flex-direction: column;
      width: 100%;
      height: 20vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  }
  
  @media screen and (min-width:800px) and (max-width: 1180px) {   
    .fa-bars {
      color: #ef4623;
      margin-right: 5px;
    }
    }