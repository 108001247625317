.insta {
    position: fixed;
    cursor: pointer;
    bottom: 50px;        
    right: 0;  
    border:none;
    z-index: 1;
    width: 40px;   
    height: 40px;    
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
