.banner{
    width: 100%;
    height: 50%;
    padding-top: 0px;  
   
}

.bannerimg{
    
   width: auto;
    height:56vw;
    
}

.imgclosure
{
    width: 100%;
    height: 100vh;
}
.rcs_sliderSet {
	height: 370px;
    
}

.rcs_sliderBox{
    height: 370px; 
}

@media screen and (min-width:768px) and (max-width: 960px) {
    .rcs_left {
        top:20%;
    }
    .rcs_right {
        top:20%;
        margin-right: 80px;
    }

    .rcs_sliderSet {
        height: 200px;
    }
    .rcs_sliderBox{
        height: 200px !important; 
        width:80%
    }

    .rcs_dotsSet{
        margin:-4px auto !important;
    } 
    .rcs_arrowBtn {
        height:20px !important;
        width:20px !important;
    }
    .banner{
        width:100%;
    }
    /* img{
        width: 100%;
    } */
}

@media screen and (min-width:375px) and (max-width: 736px) {

    /* .img{
        width: 100%;
    } */
    .rcs_sliderSet {
        height: 200px;
    }

    .rcs_sliderBox{
        padding-top: 0px;
        height: 200px !important; 
        width:70%
    }
    .rcs_left {
        top:26%;
    }
    .rcs_right {
        top:26%;
    }

    .rcs_dotsSet{
        margin:-4px auto !important;
    }   

    .rcs_arrowBtn {
        height:20px !important;
        width:20px !important;
    }
  
    .banner{
        width:100%;
    }
    img{
        width: 100%;
    }
}

@media screen and (min-width:320px) and (max-width: 400px) {

     .imgclosure
    {
        width: 100%;
        height: 100%;
    }
    .rcs_sliderSet {
        height: 200px;
    }

    .rcs_sliderBox{
        padding-top: 0px;
        height: 200px !important; 
        width:300px !important;
    }
    .rcs_left {
        top:26%;
    }
    .rcs_right {
        top:26%;
    }

    .rcs_dotsSet{
        margin:-5px auto !important;
    }   

    .rcs_arrowBtn {
        height:20px !important;
        width:20px !important;
    }
    .banner{
        width:100%;
    }
    /* img{
        width: 100%;
    } */
      
}

@media screen and (min-width:320px) and (max-width: 340px){
    .rcs_left {
        top:32%;
    }
    .rcs_right {
        top:32%;
    }
    .rcs_arrowBtn {
        height:18px !important;
        width:18px !important;
    }
}

@media screen and (min-width:375px) and (max-width:375px){
    .rcs_left {
        top:35%;
    }
    .rcs_right {
        top:35%;
    }
    .rcs_arrowBtn {
        height:18px !important;
        width:18px !important;
    }
}

    @media screen and (min-width: 1400px){
        .rcs_left {
            top:25% !important;
        }
        .rcs_right {
            top:25% !important;
        }
        .rcs_arrowBtn {
            height:18px !important;
            width:18px !important;
        }

        .rcs_sliderBox{
            height: 370px !important; 
            width:150%
        }

        .rcs_slideCon .itemWrapper img{
            width:auto !important;
        }
    }

